<template>
  <div>
    <vue-headful :title="pageheading" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div id="body-content-area">
        <div v-if="!uploadedDocuments || isLoadingDocuments">
          <Loading/>
        </div>
        <div id="pagelayout" v-else>
          <div class="top-bar-spacer" style="height: 8px; width: 100%;">&nbsp;</div>

          <div class="top-level-bar">
            <div style="display: flex; justify-content: flex-end; margin-bottom: 1rem;" class="control grid-button top-bar">
              <div id="buttongroup">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <button class="button is-dark" :disabled="selectedRows.length === 0" @click="openDeleteModal">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          Delete Selected
                        </span>
                      </button>
                    </div>
                    <div class="control">
                      <button class="button is-accent" :disabled="selectedRows.length === 0" @click="downloadSelected">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          Download Selected
                        </span>
                      </button>
                    </div>
                    <div class="control">
                      <button :disabled="isLoadingDocuments" class="button is-light" @click="getDocuments">
                        <span class="icon">
                          <i v-if="!isLoadingDocuments" class="fas fa-sync-alt"></i>
                          <i v-else class="fas fa-spinner fa-spin"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        <Grid
          :style="{maxHeight: maxHeight + 'px', height: maxHeight + 'px'}"
          :pageable="{
            ...pageable,
            pageSizes: pageSizes
          }"
          class="search"
          :filter="filter"
          :data-items="uploadedDocuments"
          :sortable="true"
          :selected-field="selectedField"
          :sort="sort"
          :filterable="false"
          :page-size="20"
          :skip="skip"
          :take="take"
          :total="totalDocuments"
          :columns="columns"
          @selectionchange="onSelectionChange"
          @headerselectionchange="onHeaderSelectionChange"
          @filterchange="filterchange"
          @sortchange="sortchange"
          @pagechange="pagechange"
        >
            <template v-slot:NameCell="{props}">
              <td style="overflow: hidden; text-overflow: ellipsis; word-wrap: none; white-space: nowrap;">
                {{props.dataItem.name}}
              </td>
            </template>

            <template v-slot:TypeCell="{props}">
              <td>
                {{props.dataItem.extension.replace('.', '').toUpperCase()}}
              </td>
            </template>

            <template v-slot:SizeCell="{props}">
              <td style="text-align: right;">
                {{getFileSize(props.dataItem.fileSize)}}
              </td>
            </template>

            <template v-slot:UploadDateCell="{props}">
              <td>
                {{ props.dataItem.uploadDate| moment('timezone', getTimeZone(), 'MM/DD/YYYY') }}
              </td>
            </template>

            <template v-slot:UploadedByCell="{props}">
              <td>
                {{ props.dataItem.uploadUserName }}
              </td>
            </template>

            <template v-slot:ActionCell="{props}">
              <td style="width: 100%; padding: 0;">
                <div class="action-cell">
                  <div v-tooltip.left-start="{ content: 'Download Document', container: false, classes: ['tooltip'] }">
                    <a @click.prevent="download(props.dataItem.id)"><i class="fa fa-download"></i></a>
                  </div>
                  <div v-tooltip.left-start="{ content: 'View Document', container: false, classes: ['tooltip'] }">
                    <a href="" @click.prevent="viewDocument(props.dataItem)"><i class="fa fa-eye"></i></a>
                  </div>
                </div>
              </td>
            </template>
        </Grid>

      </div>
    </div>
    </div>

    <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
      <div class="modal-background" @click="showDeleteModal = false"></div>
      <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
          <div class="modal-card-title has-bg-danger">Confirm Delete</div>
          <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
        </header>
        <section class="modal-card-body">
          <div class="content">
            Type "<b>delete</b>" to confirm that you want to delete one or more documents.
          </div>
          <div class="field">
            <div class="control">
              <input type="text" class="input" placeholder="type `delete` and then click confirm" v-focus
                v-model.trim="confirmationText" @keydown.enter.prevent="deleteIfConfirmed" />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <a class="button is-danger" @click="deleteIfConfirmed" :disabled="confirmationText !== 'delete'">
            <span class="icon">
              <i class="fal fa-trash-alt"></i>
            </span>
            <span>Confirm</span>
          </a>
          <a class="button" @click="showDeleteModal = false">Cancel</a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Grid } from '@progress/kendo-vue-grid'
import Loading from './Loading.vue'
import Vue from 'vue'

export default {
  name: 'SearchScreen',
  props: {
    query: String
  },
  components: {
    'Grid': Grid,
    Loading
  },
  data () {
    return {
      maxHeight: 0,
      confirmationText: '',
      minResults: 0,
      pageheading: 'Bank Uploads',
      documents: null,
      uploadedDocuments: null,
      showDeleteModal: false,
      totalDocuments: 0,
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      selectedField: 'selected',
      selectedRows: [],
      skip: 0,
      isLoadingDocuments: false,
      take: 20,
      pageSize: 20,
      perPage: 20,
      page: 1,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'displayName',
          title: 'Document Name',
          width: '320px',
          cell: 'NameCell'
        },
        {
          field: 'extension',
          title: 'Type',
          width: '130px',
          cell: 'TypeCell'
        },
        {
          field: 'fileSize',
          title: 'Size',
          cell: 'SizeCell'
        },
        {
          field: 'uploadDate',
          title: 'Uploaded On',
          cell: 'UploadDateCell'
        },
        {
          field: 'UploadUserName',
          title: 'Uploaded By',
          cell: 'UploadedByCell'
        },
        {
          cell: 'ActionCell',
          width: '150px'
        }
      ],
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: [
        ]
      }
    }
  },
  computed: {
    ...mapState(['clientSession', 'activeSite']),
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalDocuments
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    },
    columns () {
      return [
        { field: 'selected', width: '30px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.top-bar-spacer',
        '.top-level-bar',
        '.has-text-centered.has-background-primary',
        '#footer-area',
        '#header-area'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults < 1 ? 0 : numberOfResults
        this.perPage = numberOfResults
        this.minResults = numberOfResults
        this.maxHeight = maxHeight - 100

        this.getDocuments()
      }

      that.getListHeights('app', 50, 0, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('app', 50, 0, elements, func.bind(that))
      })
    })

    if (this.activeSite.id === 1) {
      this.pageheading = 'Bank Uploads'
    } else {
      this.pageheading = 'Business Uploads'
    }
  },
  methods: {
    async deleteIfConfirmed () {
      this.isLoadingDocuments = true
      this.showDeleteModal = false

      let deletePromises = this.selectedRows.map(id => this.axios.delete(`documents/clients/${id}`))

      Promise.all(deletePromises)
        .then(responses => {
          // All delete requests have resolved
          this.selectedRows = [] // Clear selectedRows since all items have been processed
        })
        .catch(_ => {
          // Handle error, one of the delete requests failed
          // console.error(error)
        })
        .finally(() => {
          this.isLoadingDocuments = false
          this.confirmationText = ''
          this.getDocuments()
        })
    },
    async downloadSelected () {
      this.isLoadingDocuments = true
      this.showDeleteModal = false

      let downloadPromises = this.selectedRows.map(id => this.download(id))

      Promise.all(downloadPromises)
        .then(responses => {
          this.selectedRows = [] // Clear selectedRows since all items have been processed
        })
        .catch(_ => {
          // Handle error, one of the download requests failed
          // console.error(error)
        })
        .finally(() => {
          this.isLoadingDocuments = false
          this.confirmationText = ''
          this.getDocuments()
        })
    },
    async download (documentId) {
      return this.api().download(`/documents/clients/${documentId}/content`)
    },
    openDeleteModal () {
      this.showDeleteModal = true
    },
    async getDocuments () {
      this.isLoadingDocuments = true
      this.selectedRows = []
      let response = await this.axios.get(`documents/clients?perPage=${this.take}&page=${(this.skip / this.take) + 1}&sortBy=${this.sort[0].field}&sortDirection=${this.sort[0].dir}&isInstitutionDocument=true`)

      if (response.status === 200) {
        this.totalDocuments = response.data.page.totalRecords
        this.uploadedDocuments = response.data.records.map(record => {
          return {
            selected: false,
            ...record
          }
        })
      }

      this.isLoadingDocuments = false
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'uploadedDocuments', this.uploadedDocuments.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.uploadedDocuments
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }

      event.dataItem.selected = checked
    },
    filterchange () {
      // stuff
    },
    viewDocument (document) {
      this.$router.push({
        name: 'ViewDocumentScreen',
        params: {
          id: document.id,
          document: document
        }
      })
    },
    downloadReport () {
      // TODO [BD|05-20-2021] Can't really make until we have an API
    },
    sortchange (e) {
      this.sort = e.sort
      this.getDocuments()
    },
    pagechange (e) {
      this.take = e.page.take
      this.skip = e.page.skip

      this.getDocuments()
    }
  }
}
</script>

<style scoped>

.k-grid tbody tr {
  cursor: pointer!important;
}

.action-cell {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 25px;
}

@media (min-width: 800px) {
  td .action-cell a {
    display: none;
    margin: 0 .25rem;
  }
}

tr:hover td .action-cell a {
  display: inline-block;
}

.k-grid td:first-child {
  color: #424242!important;
}

.k-grid-header .k-header > .k-link {
  color: #424242!important;
}

tr:hover {
  cursor: pointer!important;
}

.tooltip {
  font-size: 12px!important;
  padding: 5px!important;
}

</style>
